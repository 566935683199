import SectionServicios from "../../components/SectionServicios/SectionServicios"
import CarruselCustom from "../../components/carruselCustom/CarruselCustom"
import { NavbarCustom } from "../../components/nav-bar/NavbarCustom"
import FooterCustom from "../../components/footerCustom/footerCustom"

import ImageDr from "../../components/imageDr/imageDr";
//imagenes
import Obste from '../../images/medicos/ObstetraLiset Sánchez Montenegro.png';
import TecDental from '../../images/medicos/Tecnico dental Michelle Leon de la Torre .png';
import Odont from '../../images/medicos/Odontologo Alejandro Vélez Montoya.png'
//images
import img1 from '../../images/1.jpeg'
//css
import './Inicio.scss'

const Inicio = () => {
    return (
        <>
            <div>
            {/* <NavbarCustom /> */}
                <div className="container-fluid" id='app'>
                        <CarruselCustom />
                    <div className="container" id='servicios'>
                        <SectionServicios />
                    </div>
                    <div className='row ' id="row1">
                        <div className="col justify-content-center" id="columna1">
                            <p id="qs">¿Quienes Somos?</p>
                            <div>
                                <p id="txtqs">Hoy somos un centro médico, donde nuestro talento esta enfocado a satisfacer las necesidades de nuestros clientes y promover un estilo de Vida saludable.</p>
                            </div>
                        </div>
                        <div className="col justify-content-center" id="columna2">
                            <p id="pq1">¡Sentirse bien es uno de los pasos hacia una vida plena!</p>
                            <div><img src={img1} id="im1" /></div>

                        </div>

                    </div>
                    <div className='row' id="block1">
                        <p id="qs2">Contamos con</p>
                        <p id="qs2">Los mejores Profesionales de la Salud</p>
                        <div>
                            <p id="txtqs1">¡Quienes día a día con su labor, de manera directa o indirecta ayudan a que la salud de sus pacientes estén en las mejores condiciones posibles!</p>
                        </div>
                    </div>

                </div>
                <div id='sectiondr'>

                <div id='containerDr'>
                    <ImageDr
                        name='Tnlgo. Michelle Leon'
                        ocupation='Mecánico Dental'
                        src={TecDental}
                    />
                </div>
                <div id='containerDr'>
                    <ImageDr
                        name='Od. Alejandro Vélez '
                        ocupation='Odontólogo '
                        src={Odont}
                    />
                </div>
                <div id='containerDr'>
                    <ImageDr
                        name='Obs. Liset Sánchez'
                        ocupation='Obstetra'
                        src={Obste}
                    />
                </div>
            </div>
                <FooterCustom />
            </div>
        </>
    )
}


export default Inicio