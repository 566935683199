import './imageDr.scss'

import {imageDrProps} from './types'

const imageDr:React.FC<imageDrProps> = ({...props}:imageDrProps)  => {
  return (
    <div>
      <img src={props.src} className="img-fluid" id='imgdr' ></img>
      <p id='nameandocupation'>
        {props.name}
      </p>
      <p id='nameandocupation'>
      {props.ocupation}
      </p>
    </div>
  )
}

export default imageDr