import Button from 'react-bootstrap/Button';
import { buttonProps } from './types';
import React from 'react';

const ButtomCustom:React.FC<buttonProps> = ({...props}:buttonProps) => {
    return(
        <>
        <Button style={{backgroundColor:props.color, margin:0}} /*onClick={props.onClick}*/>{props.text}</Button>
        </>
    )
}
  
export default  ButtomCustom;