import Nav from "react-bootstrap/Nav"
import IconCustom from "../iconCustom/iconCustom"
import "./Itemnavbar.scss";

//types
import { ItemNavBarProps } from "./types";

const Itemnavbar = ({...props}:ItemNavBarProps) => {
    return (
        <Nav>
            <Nav.Link  href={props.href}>
                <div className="row" id="contenedor" >
                    <div className="col" id="icon_cont">
                        <IconCustom
                            iconName={props.iconName}
                            color={props.color}
                            size={props.size}
                            className="align-top"
                        />
                    </div>
                    <div className="col" id="text_cont"> 
                        <div className="row">
                            {props.textOne}
                        </div>
                        <div className="row" id="tex_2">
                            {props.textTwo}
                        </div>
                    </div>
                </div>
            </Nav.Link>
        </Nav>
    )
}

export default Itemnavbar