import './Citas.scss'
import FooterCustom from '../../components/footerCustom/footerCustom'

const Citas = () => {
  return (
    <>
    <div className='container'>
      <h2>Citas</h2>
      <p id='text1'>Para poder servirte de la forma que usted se merece, por favor verifica nuestra disponibilidad en el siguiente calendario y luego contacte mediante nuestro canal de agendamiento.</p>
      <p><b>Contactate al número:</b> +593999360024</p>
      <p>o envianos un mensaje directo por WhatsApp <a href='https://api.whatsapp.com/send?phone=+593999360024&text=Por+favor+me+puede+ayudar+con+una+cita%3A+' target="_blank" rel="noopener noreferrer">presionando aquí.</a></p>
      <div id="calendar">
        
        <iframe id='calendarframe'src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=America%2FGuayaquil&showTitle=0&showCalendars=0&showPrint=0&mode=WEEK&src=YmlvbWVkc2FsdWQwNUBnbWFpbC5jb20&color=%23039BE5"  scrolling="no"></iframe>

      </div>
    </div>
     <FooterCustom />
     </>
  )
}

export default Citas