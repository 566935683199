import * as Icon from 'react-bootstrap-icons';
import IconsProps from './types';

const  IconCustom =({iconName, ...props}: IconsProps)=> {
    const BootstrapIcon = Icon[iconName]
    return(
        <BootstrapIcon {...props}/>
    )
}

export default IconCustom