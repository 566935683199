import React from 'react';

import './App.scss';

import { NavbarCustom } from './components/nav-bar/NavbarCustom';
import CarruselCustom from './components/carruselCustom/CarruselCustom';
//ESTILOS DE CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import SectionServicios from './components/SectionServicios/SectionServicios';
import Inicio from './pages/Inicio/Inicio';
import {Routes, Route} from 'react-router-dom'
import Servicios from './pages/Servicios/Servicios';
import Ubicacion from './pages/ubicacion/Ubicacion';
import Citas from './pages/Citas/Citas';
import QuienesSomos from './pages/QuienesSomos/QuienesSomos';




function App() {
  return (
    <>
      {/* <Inicio/> */}
      <NavbarCustom />
      <Routes >
          
          <Route path='/' element={<Inicio/>} />
          <Route path='/servicios' element={<Servicios/>} />
          <Route path='/ubicacion' element={<Ubicacion/>} />
          <Route path='/citas' element={<Citas/>} />
          <Route path='/quienessomos' element={<QuienesSomos/>} />

      </Routes>
    </>
  );
}

export default App;
