import FooterCustom from '../../components/footerCustom/footerCustom'
import './QuienesSomos.scss'

const QuienesSomos = () => {
    return (
        <>
            <div className='container' id='containerQS'>
                <p id='titlesQS'>¿Quienes Somos?</p>
                <p id='parrafos'>Somos un centro médico de atención ambulatoria, contamos con profesionales altamente calificados y especializados, con una gama de servicios.</p>
                <p id='parrafos'>Nos preocupamos siempre en el bienestar de la comunidad y sociedad, promoviendo continuas charlas de prevención y campañas informativas de las distintas enfermedades que aquejan a la comunidad siempre con  calidad y calidez para el bienestar familiar que tu te mereces.</p>
                <p id='titlesQS'>Misión</p>
                <p id='parrafos'>Equipo médico conjunto capacitado para un manejo multidisciplinario, que pueda
                    brindar atención médica, iniciando desde el primer nivel, con el fin de prevenir, tratar
                    y rehabilitar la salud de nuestros pacientes, en asistencia de especialistas adecuados
                    para cuidar de la salud al coste más asequible que pueda brindar nuestra sociedad.</p>
                <p id='titlesQS'>Visión</p>
                <p id='parrafos'>Somos un equipo médico calificado cuyo objetivo es ofrecer servicios de salud seguros,
                    confiables, y accesibles a los mejores costos, Con el fin de preservar y cuidar de la
                    salud de nuestros pacientes con la mejor provisión de asistencia médica, otorgándoles
                    todo lo que necesiten para prevenir, proteger y rehabilitar su salud.</p>
                <p id='titlesQS'>Valores</p>
                <p id='parrafos'>Equipo médico que trabaja con una dinámica laboral acogedora que asegura bajo
                    valores bioéticos, trabajar con pericia sobre la salud de nuestros pacientes,
                    preservando el respeto, la autonomía, beneficencia y no maleficencia de los mismos,
                    construyendo día a día, bajo valores de equidad y discernimiento una responsabilidad
                    social en salud preventiva, y continuamente dando mejoras organizacionales para la
                    resolución de problemas de salud de mayor complejidad, mejorando así la atención
                    segura a nuestros pacientes.</p>
            </div>
            <FooterCustom />
        </>
    )
}

export default QuienesSomos