import ubicacion from "../../images/ubicacionbiomed.png";
import FooterCustom from '../../components/footerCustom/footerCustom'
import './Ubicacion.scss';

const Ubicacion = () => {
    return (
        <>
            <div id="c1">
                <div id="c2">
                    <img src={ubicacion} id="imgubi" />
                </div>
                <div className="container" id="c3">
                    <h2 className="text-center">Babahoyo</h2>
                    <div className="container">
                        <p id="tituloUbic">Dirección</p>
                        <p>Av Jaime Roldos entre 5 de Junio y García Moreno</p>
                        <p id="tituloUbic">Horario</p>
                        <p>Lunes a Domingo desde las 10:00 am a 19:00 pm.</p>
                    </div>

                    <div className="container" id="map">
                        <p id="tituloUbic">Mapa de Ubicación</p>
                        <iframe id="idframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.8433024118954!2d-79.5392356!3d-1.8028704000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d294c1d91e617%3A0xabcbcd6ea10aa7b3!2sBIOMED%20SALUD!5e0!3m2!1ses-419!2sec!4v1700236249721!5m2!1ses-419!2sec"  loading="lazy" ></iframe>
                    </div>


                </div>
            </div >
            <FooterCustom />
        </>
    )
}

export default Ubicacion