///carrusel
import Carousel from 'react-bootstrap/Carousel';
//scss
import './carruselCustom.scss';
// //imagenes
// import img1 from '../../images/banners/1.jpeg'
// import img2 from '../../images/banners/2.jpeg'
// import img3 from '../../images/banners/3.jpeg'
// import img4 from '../../images/banners/4.jpeg'
// import img5 from '../../images/banners/5.jpeg'
// import img6 from '../../images/banners/6.jpeg'
// import img7 from '../../images/banners/7.jpeg'
// import img8 from '../../images/banners/8.jpeg'


const CarruselCustom = ()=> {
  return (
    <>

    <Carousel className="carousel slide" data-bs-theme="dark"  interval={2000} indicators={false}>
            <Carousel.Item >
              <img
                className="d-block w-100"
                //src={img1}
                src = 'https://firebasestorage.googleapis.com/v0/b/biomedsalud-b7bda.appspot.com/o/1.jpeg?alt=media'
                alt="First slide"
                width={'auto'}
                id='imageCarrusel'
              />
            </Carousel.Item>
            <Carousel.Item >
              <img
                className="d-block w-100"
                //src={img2}
                src = 'https://firebasestorage.googleapis.com/v0/b/biomedsalud-b7bda.appspot.com/o/2.jpeg?alt=media'
                alt="Second slide"
                width={'auto'}
                id='imageCarrusel'
              />
            </Carousel.Item>
            <Carousel.Item >
              <img
                className="d-block w-100"
                //src={img3}
                src = 'https://firebasestorage.googleapis.com/v0/b/biomedsalud-b7bda.appspot.com/o/3.jpeg?alt=media'
                alt="Third slide"
                width={'auto'}
                id='imageCarrusel'
              />
            </Carousel.Item>
            <Carousel.Item >
              <img
                className="d-block w-100"
                //src={img4}
                src = 'https://firebasestorage.googleapis.com/v0/b/biomedsalud-b7bda.appspot.com/o/4.jpeg?alt=media'
                alt="Third slide"
                width={'auto'}
                id='imageCarrusel'
              />
            </Carousel.Item>
            <Carousel.Item >
              <img
                className="d-block w-100"
                //src={img5}
                src = 'https://firebasestorage.googleapis.com/v0/b/biomedsalud-b7bda.appspot.com/o/5.jpeg?alt=media'
                alt="Third slide"
                width={'auto'}
                id='imageCarrusel'
              />
            </Carousel.Item>
            <Carousel.Item >
              <img
                className="d-block w-100"
                //src={img6}
                src = 'https://firebasestorage.googleapis.com/v0/b/biomedsalud-b7bda.appspot.com/o/6.jpeg?alt=media'
                alt="Third slide"
                width={'auto'}
                id='imageCarrusel'
              />
            </Carousel.Item>
            <Carousel.Item >
              <img
                className="d-block w-100"
                //src={img7}
                src = 'https://firebasestorage.googleapis.com/v0/b/biomedsalud-b7bda.appspot.com/o/7.jpeg?alt=media'
                alt="Third slide"
                width={'auto'}
                id='imageCarrusel'
              />
            </Carousel.Item>
            <Carousel.Item >
              <img
                className="d-block w-100"
                //src={img8}
                src = 'https://firebasestorage.googleapis.com/v0/b/biomedsalud-b7bda.appspot.com/o/8.jpeg?alt=media'
                alt="Third slide"
                width={'auto'}
                id='imageCarrusel'
              />
            </Carousel.Item>
          </Carousel>
        
    </>
  )
}


export default CarruselCustom;