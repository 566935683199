import './footerCustom.scss';
import ImageDr from "../imageDr/imageDr";
import IconCustom from '../iconCustom/iconCustom';
//imagenes
import Obste from '../../images/medicos/ObstetraLiset Sánchez Montenegro.png';
import TecDental from '../../images/medicos/Tecnico dental Michelle Leon de la Torre .png';
import Odont from '../../images/medicos/Odontologo Alejandro Vélez Montoya.png'

const footerCustom = () => {
    const date = new Date;
    return (
        <div id='footer'>
            {/* <div id='sectiondr'>

                <div id='containerDr'>
                    <ImageDr
                        name='Tnlgo. Michelle Leon'
                        ocupation='Mecánico Dental'
                        src={TecDental}
                    />
                </div>
                <div id='containerDr'>
                    <ImageDr
                        name='Od. Alejandro Vélez '
                        ocupation='Odontólogo '
                        src={Odont}
                    />
                </div>
                <div id='containerDr'>
                    <ImageDr
                        name='Tnlgo. Liset Sánchezo'
                        ocupation='Obstetra'
                        src={Obste}
                    />
                </div>
            </div> */}
            <div className='container' id='linksfooter'>
                <div className='text-center'>
                    <p id='title'>BIOMED SALUD</p>
                    <div className='row'>
                        <a href='https://www.facebook.com/biomedsaludcentromedico' target='_blank' rel="noopener noreferrer" className='col'><IconCustom iconName='Facebook' size={20} /></a>
                        <a href='https://www.instagram.com/biom.edsalud/?igshid=OGQ5ZDc2ODk2ZA%3D%3D'target='_blank' rel="noopener noreferrer" className='col'><IconCustom iconName='Instagram' size={20} /></a>
                        <a href='https://www.tiktok.com/@biomedsalud?_t=8hbYA3jjWCM&_r=1' target='_blank' rel="noopener noreferrer" className='col'><IconCustom iconName='Tiktok' size={20} /></a>
                        {/* <a href='/#' className='col'><IconCustom iconName='Google' size={20} /></a> */}
                    </div>

                </div>
                <div className='text-center'>
                    <p id='title2'>Servicios</p>
                    <p><a id='aref' href='/ubicacion'>Ubicacion</a></p>
                    <p><a id='aref' href='/servicios'>Doctores</a></p>
                </div>
                <div className='text-center'>
                    <p id='title2'>Centro Médico</p>
                    <p><a id='aref' href='/quienessomos'>Quienes Somos</a></p>
                    <p><a id='aref' href='/quienessomos'>Misión</a></p>
                    <p><a id='aref' href='/quienessomos'>Visión</a></p>
                </div>
                
            </div>
            <div className='container text-center'>
                    <p >© All Rights Reserved KEILLEI, {date.getFullYear()}</p>
                </div>
        </div>
    )
}

export default footerCustom

