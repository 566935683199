import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

//logo
import logo from "../../images/logo.png";
import Itemnavbar from '../item-nav/Itemnavbar';
import { colors } from '../colors/colors';
import ButtomCustom from '../buttonCustom/buttomCustom';


//bavigate button
const handleNavigate = () =>{
    
}

export const NavbarCustom = () => {
    
    return (
        <Navbar sticky="top" expand="lg" className="bg-body-tertiary" id='nav-bar'>
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt=''
                        src={logo}
                        width={50}
                        height={50}

                    />{' '}
                    Biomed Salud
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Itemnavbar 
                        iconName={"TelephoneFill"}
                        color={colors.secondary}
                        size={25}
                        textOne='Call Center'
                        textTwo='+593999360024'
                        
                        />
                        <Itemnavbar 
                        iconName={"PinMapFill"}
                        color={colors.secondary}
                        size={25}
                        textOne='Ubicación'
                        textTwo='Av Jaime Roldos | Babahoyo'
                        href='/ubicacion'
                        />
                        <Itemnavbar 
                        iconName={"ClockFill"}
                        color={colors.secondary}
                        size={25}
                        textOne='Lun - Dom:'
                        textTwo='10:00 - 19:00'
                        />
                    </Nav>
                    <Nav.Link  href={'/citas'} >
                         <ButtomCustom
                        color={colors.secondary}
                        text='Agendar cita'
                        //onClick={()=>console.log}
                    /></Nav.Link>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}