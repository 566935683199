import FooterCustom from "../../components/footerCustom/footerCustom";

import './Servicios.scss'
//imagenes
import medicina from "../../images/servicios/medicina.png";
import odont from "../../images/servicios/odont.jpeg";
import psico from "../../images/servicios/pscico.jpeg";
import obst from "../../images/servicios/obst.jpeg";
import mecdent from "../../images/servicios/mecdent.jpeg";
const Servicios = () => {
    return (
        <>
            <div>
                <section className="container" id='secmedicina'>
                    <div className='row'>
                        <div className="col" >
                            <p id="titulos">Medicina</p>
                            <ul>
                                <li>Consulta médica, detección y prevención de enfermedades.</li>
                                <li>Control de niños y adultos mayores, detección de malnutrición y crecimiento continuo.</li>
                                <li>Control de crecimiento en adolescentes detección de factores de riesgo.</li>
                                <li>Realización de procedimientos médicos como cambios o colocación de sondas nasogástricas o vesicales.</li>
                                <li>Cirugía menor y curaciones de heridas.</li>
                            </ul>


                        </div>
                        <div className="col justify-content-center" >
                            <img src={medicina} id="imgserv" />
                        </div>
                    </div>
                </section>
                <section className="container" id='odontologia'>
                    <div className='row'>
                        <div className="col justify-content-center" >
                            <img src={odont} id="imgserv" />
                        </div>
                        <div className="col" >
                            <p id="titulos">Odontología</p>
                            <ul>
                                <li>Profilaxis dental.</li>
                                <li>Calces dentales.</li>
                                <li>Extracciones dentales.</li>
                                <li>Prótesis dentales.</li>
                                <li>Blanqueamiento dental.</li>
                                <li>Diseño de sonrisa.</li>
                            </ul>
                        </div>

                    </div>
                </section>
                <section className="container" id='obstreticia'>
                    <div className='row'>
                        <div className="col" >
                            <p id="titulos">Obstetricia</p>
                            <ul>
                                <li>Consulta obstétrica continua.</li>
                                <li>Control prenatal y preparación del embarazo, detección de factores de riesgo.</li>
                                <li>Planificación familiar y administración de métodos anticonceptivos.</li>
                                <li>Toma de papanicolaou.</li>
                                <li>Lactancia materna enseñanza continua.</li>
                                <li>Tamizaje de VIH, sífilis.</li>
                                <li>Control puerperal y detección de factores de riesgo puerperales.</li>
                            </ul>


                        </div>
                        <div className="col justify-content-center" >
                            <img src={obst} id="imgserv" />
                        </div>
                    </div>
                </section>
                <section className="container" id='mecanicadental'>
                    <div className='row'>
                        <div className="col justify-content-center" >
                            <img src={mecdent} id="imgserv" />
                        </div>
                        <div className="col" >
                            <p id="titulos">Mecánica Dental</p>
                            <ul>
                                <li>Profilaxis dental.</li>
                                <li>Calces dentales.</li>
                                <li>Extracciones dentales.</li>
                                <li>Prótesis dentales.</li>
                                <li>Blanqueamiento dental.</li>
                                <li>Diseño de sonrisa.</li>
                            </ul>
                        </div>

                    </div>
                </section>
                <section className="container" id='psicologia'>
                    <div className='row'>
                        <div className="col" >
                            <p id="titulos">Psicología</p>
                            <ul>
                                <li>Actividades de prevención y promoción, consejo y apoyo para el mantenimiento de la salud.</li>
                                <li>Detección, diagnóstico y tratamiento de trastornos adaptativos, por ansiedad y depresión.</li>
                                <li>Detección, diagnóstico y tratamiento de trastornos infantiles como autismo, trastornos de déficit de atención e hiperactividad (tdah), asperger, entre otros.</li>
                                <li>Detección de trastornos del comportamiento en salud mental.</li>
                                <li>Detección de psicopatologías de la infancia/ adolescencia, incluidas los trastornos de conducta en general y alimentaria en particular, como anorexia o bulimia.</li>
                                <li>Seguimiento de forma coordinada con los servicios médicos especializados en salud mental.</li>
                            </ul>


                        </div>
                        <div className="col justify-content-center" >
                            <img src={psico} id="imgserv" />
                        </div>
                    </div>
                </section>
                <section className="container" id='servadicionales'>
                    <div className='row'>
                        <div className="col justify-content-center" >
                            <img src={medicina} id="imgserv" />
                        </div>
                        <div className="col" >
                            <p id="titulos">Servicios Adicionales</p>
                            <ul>
                                <li>Intravenoso.</li>
                                <li>Control de presión.</li>
                            </ul>
                        </div>

                    </div>
                </section>
                <div>
                    <FooterCustom />
                </div>
            </div>
        </>
    )
}



export default Servicios