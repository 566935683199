
import './SectionServicios.scss';
import ItemServicio from "../itemServicio/itemServicio";
//imagenes
import medicina from "../../images/servicios/iconetetos.png";
import adicionales from "../../images/servicios/adicionales.png";
import odontologia from "../../images/servicios/odontologia.png";
import mecdental from "../../images/servicios/mecanico dental.png";
import obstret from "../../images/servicios/obdteticia.png";
import psico from "../../images/servicios/psicologia.png";



const SectionServicios = () => {
  return (
    <>
      <div className='container' id="idsectionServicios">
        <div id='titulo'>
          <h2 >Nuestros Servicios</h2>
          <hr id='linea'></hr>

          <div className='row justify-content-center' id='containerItems'>
            <div className="col-4">
              <ItemServicio
                name='Medicina'
                src={medicina}
                to='/servicios#secmedicina'
              />
            </div>
            <div className="col-4">
              <ItemServicio
                name='Odontología'
                src={odontologia}
                to='/servicios#ondontologia'
              />
            </div>
            <div className="col-4">
              <ItemServicio
                name='Mecánica Dental'
                src={mecdental}
                to='/servicios#mecanicadental'
              />
            </div>

          </div>
          <div className='row justify-content-center' id='containerItems'>
            <div className="col-4">
              <ItemServicio
                name='Obstetricia'
                src={obstret}
                to='/servicios#obstreticia'
              />
            </div>
            <div className="col-4">
              <ItemServicio
                name='Psicología'
                src={psico}
                to='/servicios#psicologia'
              />
            </div>
            <div className="col-4">
              <ItemServicio
                name='Servicios Adicionales'
                src={adicionales}
                to='/servicios#servadicionales'
              />
            </div>

          </div>
        </div>

      </div>
    </>
  )
}

export default SectionServicios