import './itemServicio.scss';
import { NavHashLink } from 'react-router-hash-link';
import { itemServicioProps } from './types'


const ItemServicio: React.FC<itemServicioProps> = ({ ...props }: itemServicioProps) => {
  return (
    <div id='containerItem'>
      {/* <a href='#/' id='itemServicio'> */}
      <div id='itemServicio'>
        <NavHashLink style={{textDecoration:'none', color:'#000000'}}
          to={props.to}
          smooth  
        >
          <img src={props.src} id='image'
          />
          <p>{props.name}</p>
        </NavHashLink>
      </div>
      {/* </a> */}
    </div>
  )
}

export default ItemServicio
